.resetPopup{
    font-size: 1.1vw;
    background-color: rgba(0, 0, 0, 0.35);

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 10;
}

.popupContent{
    color: #f0f3f8;

    width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2vw 3.5vw;
}

.statusSpan{
    font-size: 1vw;
}

.resetButtons{
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: end;
}
.resetPopupBtn{
    color: #f0f3f8 !important;
    margin: 1vh;
}
.resetPasswordInput{
    font-size: 1vw;
}