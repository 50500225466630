.register{
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 6vw;
    bottom: 0;
    width: 100%;

    padding: 0vh 4vw;

    font-size: 1.2vw;
    color: rgb(240, 243, 248);

    font-family: 'Titillium Web', sans-serif;
}

.registerContainer{
    padding: 4vh 1vw;
    width: 75%;
}

.noMargin{
    margin: 0vw 0vw !important;
}

.registerFormCol{
    padding-right: 2vw;
}
.registerFormGroup{
    margin-bottom: 2vh;
}
.registerFormGroup > .row{
    margin: 1vh 0vw;
}

.registerIconText{
    font-size: 1.4vw;
}

.registerBtn{
    cursor: pointer;

    font-size: 1vw;

    border-style: solid;
    border-color: rgb(240, 243, 248);
    border-width: 1px;
    border-radius: 1vw;

    width: 47%;
    margin: 0 auto;
    padding: 0.6vw 2vw;
    background-color: #f0f3f817;
}
.registerBtn:hover{
    background-color: #f0f3f832;
}
.registerBtnIcon{
    margin: 0vw 0.4vw;
}
.registerIcon{
    width: 12vw;
    margin: 0 auto;
}

.registerInput{
    color: #062e5b;
    font-size: 1vw;
}

/*form placeholders*/
.registerInput::placeholder { 
    font-style: italic;
    color: #062e5b;
    opacity: 0.8; 
    font-size: 1vw;
}
.registerInput:-ms-input-placeholder { 
    font-style: italic;
    color: #062e5b;
    opacity: 0.8; 
    font-size: 1vw;
}
.registerInput::-ms-input-placeholder { 
    font-style: italic;
    color: #062e5b;
    opacity: 0.8; 
    font-size: 1vw;
}

.registerErrorText{
    margin-top: 1vh;
    text-align: center;
    font-weight: 600;
    color: red;
    font-size: 1vw;
}



/* SMALL SCREENS */
@media (max-width: 575px) {
    .body{
        min-height: fit-content;
    }
    .register{
        top: 10vh;
        min-height: 90vh;
    }
    .registerContainer{
        width: 90%;
    }
    .registerIconText{
        font-size: 7vw;
    }
    .registerIcon{
        width: 35vw;
        margin-bottom: 2vh;;
    }
    .registerFormCol{
        font-size: 5vw;
    }

    .registerInput{
        font-size: 5vw;
        margin-bottom: 0.5vh;
    }
    /*form placeholders*/
    .registerInput::placeholder { 
        font-size: 5vw;
    }
    .registerInput:-ms-input-placeholder { 
        font-size: 5vw;
    }
    .registerInput::-ms-input-placeholder { 
        font-size: 5vw;
    }

    .registerBtn{
        padding: 1vh 0vw;
        width: 82.5%;
        font-size: 5vw;
    }
    .registerErrorText{
        font-size: 5vw;
    }
    .suggestionSpan{
        width: 100%;
        bottom: 1vh;
        margin-right: 0px !important;
        text-align: center;
        font-size: 3.5vw !important;
    }
}