.barHeader{
    position: relative;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    height: 6vw;
    z-index: 2;

    border-style: solid;
    border-width: 1px 0px 1px 0px;
    border-color: rgb(240, 243, 248, 1);
    border-top-color: rgb(60, 75, 117);

    background-color: #094fa0cc;
    filter: drop-shadow(-4px 6px 15px #000);

    font-family: 'Titillium Web', sans-serif;
}

.barContainer{
    color: rgb(240, 243, 248, 1);
    width: 100vw !important;
    font-size: 0.9vw;

    margin: 0 auto;

    width: 95% !important;
}
.lightShadow{
    filter: drop-shadow(-4px 6px 15px rgba(0, 0, 0, 0.196));
    text-shadow: 2px 2px 4px #070318;
}

.barRow{
    margin: 0;
}

.barIcon{
    width: 6.3vw;
    float: left;
    position: relative;
    bottom: 0.1vh;
}
.barIcon2{
    width: 1.2vw;
    position: relative;
    bottom: 0.1vh;
}
.barIcon3{
    width: 1.2vw;
    position: relative;
    bottom: 0.1vh;
    margin-left: 0.2vw;
}
.barLink{
    color: rgba(240, 243, 248, 0.9);

    margin: 0vw 0.6vw;
    text-decoration: none;
}
.barLink:hover{
    color: white;
    text-decoration: underline;
    cursor: pointer;
}
.barSecondCol{
    text-align: start;

    font-size: 1.5vw;
}
.barIcon3{
    width: 2vw;
    margin-bottom: 0.2vh;
}
.linksCol{
    height: 6vw !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.links{
    text-align: end;
    white-space:nowrap;
}

.userCol{
    height: 6vw !important;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.registerLink{
    border-style: solid;
    border-color: rgba(240, 243, 248, 0.905);
    padding: 1vw;
    border-radius: 1vw;
    border-width: 1px;
    background-color: rgba(240, 243, 248, 0.169);

    white-space:nowrap;
    text-decoration: none !important;
}
.registerLink:hover{
    background-color: rgba(240, 243, 248, 0.327);
}

.loginLink{
    text-decoration: underline;
    white-space: nowrap;
}

.barheaderUser{
    font-size: 1.2vw;
    font-weight: 400;
    color:rgba(240, 243, 248, 0.95);
    cursor: pointer;
    
    border-style: solid;
    border-color: rgb(240, 243, 248. 0.9);
    padding: 1vw;
    border-radius: 1vw;
    border-width: 1px;
    background-color: rgba(240, 243, 248, 0.1);
}



/* SMALL SCREENS */
@media (max-width: 575px) {
    .barHeader{
        height: 10vh;
        text-align: center;
    }
    .barContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;

        max-width: 100vw !important;
        margin: 0px;
        padding: 0px;
        height: 10vh;
    }
    .barRow{
        height: 10vh;
        width: 100vw !important;
    }
    .barRow > div{
        padding: 0px !important;
    }

    #bar-icon-col{
        overflow: hidden;
    }
    .barIcon{
        width: 20vw;
        margin: 0 auto;
    }
    #bar-current-calculator{
        font-size: 350%;
    }
    #bar-current-calculator-icon{
        width: 7vw;
    }

    .barButtonRow{
        height: 10vh;
        line-height: 10vh;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .barButton{
        height: 5vh;
        font-size: 900%;

        border-style: solid;
        border-width: 1px;
        border-color: rgb(240, 243, 248, 1);
        border-radius: 4px;
        
        padding: 1vw 2vw;
        margin: 2vh 1vw;
    }

    #bar-aux-div{
        width: 100vw;
        background-color: #094fa0ad;
        border-bottom: rgba(240, 243, 248, 0.667) 1px solid;
        padding: 1.5vh 1vw;
    }
    #bar-aux-content{
        padding: 1vh 2vw;
        color: rgb(240, 243, 248, 1);
    }
    .barIcon2{
        width: 5vw;
    }

    .barheaderUser{
        font-size: unset;
    }
}