.result, .overlay{
    z-index: 2;
    font-size: 1.1vw;
    background-color: rgba(0,0,0,0.6);

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.resultTitle{
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
    font-size: 2.2vw;

    text-shadow: 
        -1px -1px 0px #094fa0ea,
         0px -1px 0px #094fa0ea,
         1px -1px 0px #094fa0ea,
        -1px  0px 0px #094fa0ea,
         1px  0px 0px #094fa0ea,
        -1px  1px 0px #094fa0ea,
         0px  1px 0px #094fa0ea,
         1px  1px 0px #094fa0ea;
}
.resultSubtitle{
    font-size: 1.2vw;
    font-weight: 600;
}
.noMeasurements{
    font-size: 0.8vw;
}
.resultPRR{
    font-size: 1vw;
}

.resultResume{
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 0.8vw;
}

.resultContent{
    z-index: 2;
    width: 75%;

    color: #f0f3f8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 1vw 5vw;

    z-index: 4;
}
.resultCol{
    padding: 0.8vw 0.8vw;
    margin: 0vw 0.4vw;
}
.resultCol > .row{
    margin: 0vw 0vw;
}

.orientationSubtitle{
    margin-top: -0.8vh !important;
    line-height: 1.5vh;
    font-style: italic;
    font-size: 0.8vw;
}
.resultOrientationRow{
    position: relative;
    padding: 0% 25%;
    margin: 0 auto;
}
#orientationSpan{
    font-weight: 400 !important;

    border-style: solid;
    border-color: rgba(240, 243, 248, 0.116);
    border-width: 1px;
    border-radius: 10px;

    padding: 0.1vw 0.5vw 0.1vw 0.8vw;
    margin: 0vw auto;

    background-color: #094fa08f;
}
.eyeGraphic{
    position: relative;
    right: 0.8%;
    width: 60%;
    margin: 1.6vh auto 1.9vh auto;

    display: flex;
    justify-content: center;
    align-items: center;
}
#graphicCircle{
    width: 100%;
}
#graphicIOL{
    position: absolute;
    left: 6.9vw;
    width: 30%;
}
#graphicSuggested{
    position: absolute;
    left: 6.9vw;
    margin: auto 0;
    width: 30%;
    transform: rotate(82deg);
}

.rangeDiv{
    position: relative;

    margin-bottom: 0.15vw;

    height: 3vh;
}
#orientationRange{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
#rangeArrowL, #rangeArrowR{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    cursor: pointer;
    
    font-size: 0.8vw;
}
#rangeArrowL{
    left: -1vw;
}
#rangeArrowR{
    right: -1vw;
}

.optionsDiv{
    font-size: 1.6vw;
    
    padding: 5vh 0vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.optionsDiv > span{
    margin: 0vw 32%;   
}
.optionsDiv > span:hover{
    cursor: pointer;
    text-decoration: underline;
}
.optionsDiv2{
    font-size: 1.6vw;
    
    padding: 9vh 0vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.optionsDiv2 > span{
    margin: 0vw 32%;   
}
.optionsDiv2 > span:hover{
    cursor: pointer;
    text-decoration: underline;
}
.identifiersDiv{
    padding: 9vh 0vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    
    font-size: 1.5vw;
    font-style: italic;
}

.hint{
    font-weight: 400;
    padding: 0 0 2vw 0.5vw;
    cursor: pointer;
}
.hint:hover .hintText {
    display: inline-block;
}
.hintText, .hintText2{
    background-color: rgba(240, 243, 248, 0.781);
    border-color: #094fa0c2;
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;

    color: #094fa0f5;
    font-size: 0.8vw;
    text-align: left;

    position: absolute;
    width: 42.5%;
    top: 0;
    margin-left: 0.25vw;
    z-index: 2000;
    padding: 0.8vw;

    display: none;
}

.hint2:hover .hintText {
    display: inline-block;
}
.hint2{
    cursor: pointer;
    position: absolute;
    right: 18%;

    width: min-content;
}
.hintText2{
    width: 13.5vw;
}
.hintText3{
    position:absolute;
    width: 34.8vw;
    top: 3.4vh;
    left: 0vw;
    z-index: 2;
}

/* loading  */
.resultLoadingDiv{
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}



/* SMALL SCREENS */
@media (max-width: 575px) {
    .result{
        height: 100vh !important;
        overflow-y: scroll;
    }
    .resultContent{
        width: 90% !important;
        margin: 5vh auto;
        padding: 2vh 8vw;

        position: relative;
        top: 0%;
        left: 0%;
        transform: none;
    }
    .resultCol{
        padding: 0.8vw 0.8vw;
        margin: 0.5vh 0vw;
        font-size: 4vw;
    }
    .resultCol > .row{
        margin: 0vw 0vw;
    }
    .separator{
        margin: 1vh auto;
    }

    .resultTitle{
        font-size: 8vw;
    }
    .resultSubtitle{
        font-size: 5vw;
        margin-bottom: 0px !important;
    }
    .noMeasurements{
        padding: 0.3vh 2vw;
        font-size: 2.5vw;
    }
    .resultPRR{
        font-size: 2.9vw;
    }
    .resultResume{
        font-size: 3vw;
    }

    .optionsDiv, .optionsDiv2{
        margin-top: 1vh;
        padding: 0px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .optionsDiv > span, .optionsDiv2 > span {
        margin: 0px 1.5vw;
        font-size: 4.5vw;
    }
    i{
        padding: 0.2vh 1vw;
    }
    .span2{
        font-size: 3.8vw;
    }

    
    .orientationSubtitle{
        font-size: 2.5vw;
    }
    .resultOrientationRow{
        position: relative;
        padding: 0% 15%;
        margin: 0 auto;
    }
    #orientationSpan{
        font-weight: 400 !important;
    
        border-style: solid;
        border-color: rgba(240, 243, 248, 0.116);
        border-width: 1px;
        border-radius: 10px;
    
        padding: 0.1vw 0.5vw 0.1vw 0.8vw;
        margin: 0vw auto;
    
        background-color: #094fa08f;
    }
    .eyeGraphic{
        position: relative;
        right: 0.8%;
        width: 60%;
        margin: 1.6vh auto 1.9vh auto;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #graphicCircle{
        width: 180%;
    }
    #graphicIOL{
        position: absolute;
        left: 12vw;
        width: 54%;
    }
    #graphicSuggested{
        position: absolute;
        left: 12vw;
        margin: auto 0;
        width: 54%;
        transform: rotate(82deg);
    }
    
    .rangeDiv{
        position: relative;
    
        margin-bottom: 0.15vw;
    
        height: 3vh;
    }
    #orientationRange{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    #rangeArrowL, #rangeArrowR{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    
        cursor: pointer;
        
        font-size: 4vw;
    }
    #rangeArrowL{
        left: -5vw;
    }
    #rangeArrowR{
        right: -5vw;
    }
}