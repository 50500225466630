.input{
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 6vw;
    bottom: 0;
    width: 100%;

    padding: 0vh 4vw;

    font-size: 1.2vw;
    color: rgb(240, 243, 248);

    font-family: 'Titillium Web', sans-serif;
}

.hCenter{
    margin: 0 auto;
}
.hCenterFlex{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.centeredRow{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
.hCenterRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.averagesCol{
    text-align: center;
    font-size: 0.9vw;
}
.noPadding{
    padding: 0px 0px;
}
.italic{
    font-style: italic;
}
.carousel-indicators{
    display: none;
}
.carousel, .carousel-inner, .carousel-item{
    height: 99.5%;
    background: none;
}
.carousel-inner{
    width: 108.5%;
    margin-left: -4.3%;
}
.carousel-inner>.item {
    width: 100% !important;
    height: 100% !important;
}
.carouselItem2{
    margin-bottom: 0vw !important;
}


h1{
    text-align: center;
    font-size: 300%;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
}
.h1vCenter{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.title2{
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
}
option { 
    /* Whatever color  you want */
    color: #0a4269;
}

.styledBox{
    padding: 1vw 0vw;

    border-style: solid;
    border-color: rgb(240, 243, 248);
    border-width: 1px 1px 2px 2px;
    border-radius: 20px;

    background-color: #094fa0aa;
    filter: drop-shadow(-9px 5px 16px #000);
}
.formIconDiv{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 1.3vw;
}

.colorgrey{
    color: rgb(182, 169, 169) !important;
}

.formCol{
    position: relative;
}
.formCol > .row{
    margin: 0.15vw 0vw;
    padding-left: 0vw;
    
    text-align: left;

    white-space: nowrap;
}

.measureCol{
    text-align: center;
    width: fit-content;

    font-size: 1vw;
}
.measureRow{
    width: 100%;

    font-size: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
}
.addMeasureRow > span{
    margin: 0 auto;
    width: min-content;

    font-size: 0.8vw;

    border-style: solid;
    border-width: 1px;
    border-radius: 20px;

    cursor:pointer;

    background-color: #094fa0aa;
}
.addMeasureRow > span:hover{
    color: white;
    background-color: rgba(45, 111, 185, 0.98);
}
.deleteMeasureSpan{
    position: absolute;
    text-align: right;

    z-index: 2;

    padding-right: 0.6vw;
    padding-top: 0vh;

    font-size: 1.5vw;
}
#deleteButton:hover{
    color:rgb(255, 255, 255);
    cursor: pointer;
    font-size: 1.7vw;

    padding-left: 0.30vw;
}


.octSwitchOn{
    cursor: pointer;

    position: absolute;
    z-index: 7;

    top: 0;
    left: 0.5vw;
    width: calc(100% - 1vw);
    height: 100%;

    background-color: #4475adfa;
    border-style: solid;
    border-color: #9bb9dbcb;
    border-width: 1px;
    border-radius: 20px;
}
.octSwitchOn:hover{
    cursor: pointer;
    color: white;

    position: absolute;
    top: 0;
    left: 0.5vw;
    width: calc(100% - 1vw);
    height: 100%;

    background-color: #3e6694fa;
    border-style: solid;
    border-color: white;
    border-width: 1px;
    border-radius: 20px;
}
.octSwitchOff{
    position: absolute;
    top: 0;
    left: 0.5vw;
    width: calc(100% - 1.5vw);
    height: 100%;

    background-color: #4475ad00;
    border-color: #9bb9db00;
    color: #00000000;

    /*transition: nombre de la propiedad | duración | función | retardo */
    transition: all .2s linear 0s !important;
}
#addMoreCircle{
    font-size: 3vw;
}
.addMoreMeasurementsSpan{
    font-size: 1.3vw;
}
.addMoreMeasurementsSpan2{
    font-size: 0.8vw;
    margin-top: -0.5vh;
}

/*REMOVE ARROWS FROM NUMBER INPUTS*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox
input[type=number] {
  -moz-appearance: textfield;
}*/


/* SMALL SCREENS */
@media (max-width: 575px) {
    .carousel-inner{
        overflow: hidden auto !important;
    }
    .input{
        overflow: auto;
        top: 10vh;
    }
    .title{
        position: relative;
        flex-wrap: nowrap !important;
        min-height: 15vh !important;
        font-size: 3vw;
    
        color: #094fa0ea;
        text-shadow: 2px 2px 4px #484168;
    
        margin-bottom: 0px;
    
        /*TEXT SHADOW PRESET*/
        text-shadow: 
            -1px -1px 0px rgb(240, 243, 248),
             0px -1px 0px rgb(240, 243, 248),
             1px -1px 0px rgb(240, 243, 248),
            -1px  0px 0px rgb(240, 243, 248),
             1px  0px 0px rgb(240, 243, 248),
            -1px  1px 0px rgb(240, 243, 248),
             0px  1px 0px rgb(240, 243, 248),
             1px  1px 0px rgb(240, 243, 248);
    }
    .title2{
        font-size: 3.3vw;
    }
    .formIcon{
        margin: 0 auto;
        width: 15vh;
    }
    #box-icon-title{
        font-size: 4.5vw;
    }

    .xs-nogutter{
        --bs-gutter-x: 0px !important;
    }

    .inputDiv{
        display: flex;
        flex-direction: row;
        justify-content: left;
    
        position: relative;
        z-index: 6 !important;
    
        width: 100% !important;
        height: 5vh;
    
        white-space: nowrap;

        margin: 0.5vh 0vw;
    }

    /*form placeholders*/
    .form-control::placeholder { 
        color: rgb(182, 169, 169);
        opacity: 1;
    }
    .form-control:-ms-input-placeholder { 
        color: rgb(211, 203, 203);
        font-size: 3vw;
    }
    .form-control::-ms-input-placeholder { 
        color: rgb(211, 203, 203);
        font-size: 3vw;
    }

    .formRow{
        margin: 0vw !important;
    }
    .formControl{
        max-width: 100% !important;
        width: 100% !important;
        border-radius: 15px;  

        margin: 0vw 0.25vw;
        padding-left: 2vw !important;
        height: 5vh;
        line-height: normal;

        font-size: 3vw !important;

        position: relative;
    }
    .controlLabel{
        font-size: 3vw;
        
        padding: 0vw 0vw;

        height: 5vh;
        line-height: 5vh;

        display: inline-block;
    }

    .controlNeutral{
        font-size: 0.7vw;
        color: rgba(128, 128, 128, 0.728);
    }
    .controlInvalid{
        font-size: 0.9vw;
        font-style: italic;
        background-color: rgba(255, 0, 0, 0.442) !important;

        color: rgb(206, 211, 215);
    }
    .controlValid{
        font-size: 0.9vw;
        background-color: #779dd557 !important;

        color: rgb(206, 211, 215);
    }

    /* TCA MEASURES */
    .octSwitchOn{
        margin-left: -1vw;
        width: calc(100% + 1vw);
        border-radius: 10px !important;
    }
    .octSwitchOn:hover{
        width: calc(100% + 1vw);
    }
    #addMoreCircle{
        font-size: 7vw;
    }
    .addMoreMeasurementsSpan{
        font-size: 4vw;
    }
    .addMoreMeasurementsSpan2{
        font-size: 2.5vw;
        margin-top: -0.5vh;
    }
    .measureCol{
        font-size: 4vw;
    }
    .addMeasureRow > span{
        font-size: 3vw !important;
    }
    .averagesCol{
        text-align: center;
        font-size: 3vw;
    }
    .deleteMeasureSpan, .deleteMeasureSpan2, 
    .deleteMeasureSpan4{
        position: absolute;
        text-align: right;
        z-index: 2;
        height: 6vh;
        line-height: 6vh;
        font-size: 5vw !important;
    }
    .deleteMeasureSpan2, .deleteMeasureSpan4{
        left: 0.5vw;
    }
    #deleteButton:hover{
        color:rgb(255, 255, 255);
        cursor: pointer;
        font-size: 5vw;
    
        padding-left: 0vw;
    }
}

/* PC SCREENS */
@media (min-width: 576px) {
    .title{
        position: relative;
        line-height: 10%;
        height: 10%;
    
        color: #094fa0ea;
        text-shadow: 2px 2px 4px #484168;
    
        margin-bottom: 2.1vh;
    
        /*TEXT SHADOW PRESET*/
        text-shadow: 
            -1px -1px 0px rgb(240, 243, 248),
             0px -1px 0px rgb(240, 243, 248),
             1px -1px 0px rgb(240, 243, 248),
            -1px  0px 0px rgb(240, 243, 248),
             1px  0px 0px rgb(240, 243, 248),
            -1px  1px 0px rgb(240, 243, 248),
             0px  1px 0px rgb(240, 243, 248),
             1px  1px 0px rgb(240, 243, 248);
    
        filter: drop-shadow(-9px 8px 16px rgba(0, 0, 0, 0.74));
    }
    .formIcon{
        margin: 0 auto;
        width: 10vw;
    }
    .formRow{
        height: 45%;
        padding: 0%;
    }

    .inputDiv{
        display: flex;
        flex-direction: row;
        justify-content: left;
    
        position: relative;
        z-index: 6 !important;
    
        width: 100% !important;
        height: 2.5vw;
    
        white-space: nowrap;
    }    
    
    /*form placeholders*/
    .form-control::placeholder { 
        color: rgb(182, 169, 169);
        opacity: 1; 
        font-size: 0.9vw;
    }
    .form-control:-ms-input-placeholder { 
        color: rgb(211, 203, 203);
        font-size: 0.9vw;
    }
    .form-control::-ms-input-placeholder { 
        color: rgb(211, 203, 203);
        font-size: 0.9vw;
    }

    .formControl{
        max-width: 100% !important;
        width: 100% !important;
        border-radius: 15px;  

        margin: 0vw 0.25vw;
        padding: 0.1vw  0.5vw!important;

        font-size: 1vw !important;
    }
    .controlLabel{
        font-size: 1.2vw;
        
        padding: 0vw 0vw;

        height: 2.5vw;
        line-height: 2.5vw;

        display: inline-block;
    }

    .controlNeutral{
        font-size: 0.7vw;
        color: rgba(128, 128, 128, 0.728);
    }
    .controlInvalid{
        font-size: 0.9vw;
        font-style: italic;
        background-color: rgba(255, 0, 0, 0.442) !important;

        color: rgb(206, 211, 215);
    }
    .controlValid{
        font-size: 0.9vw;
        background-color: #779dd557 !important;

        color: rgb(206, 211, 215);
    }
}