.preopData{
    height: 100%;
    padding: 2vw 0vw;
    font-family: 'Titillium Web', sans-serif;

    display: flex;
    flex-direction: column;
    align-content: center;

    position: relative;
}
.nameInput{
    max-width: 30vw !important;
}
.patientNameDiv{
    margin-left: auto;
    margin-right: 0;
    
    white-space: nowrap;
}

.formCol{
    position: relative;
}
.formCol > .row{
    margin: 0.15vw 0vw;
    padding-left: 0vw;
    
    text-align: left;

    white-space: nowrap;
}

.measureCol{
    text-align: center;
    width: fit-content;

    font-size: 1vw;
}
.measureRow{
    width: 100%;

    font-size: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
}
.addMeasureRow > span{
    margin: 0 auto;
    width: min-content;

    font-size: 0.8vw;

    border-style: solid;
    border-width: 1px;
    border-radius: 20px;

    cursor:pointer;

    background-color: #094fa0aa;
}
.addMeasureRow > span:hover{
    color: white;
    background-color: rgba(45, 111, 185, 0.98);
}
.deleteMeasureSpan{
    position: absolute;
    text-align: right;

    z-index: 5;

    padding-right: 0.6vw;
    padding-top: 0vh;

    font-size: 1.5vw;
}
#deleteButton:hover{
    color:rgb(255, 255, 255);
    cursor: pointer;
    font-size: 1.7vw;

    padding-left: 0.30vw;
}

.arrowIcon{
    align-self: center;   
    margin: 0.5vw 0.5vw;
    font-size: 1.8vw;
}
.block1{
    max-width: 16.666666666667%;
    margin: 0vw 0vw 0vw 2.75vw;
}
.block2{
    margin: 0 auto;
}
.block3{
    padding: 0vw 1vw !important;
}
.block4{
    max-width: 12.3333333333334%;
    padding-left: 0vw;
    margin: 0vw 1vw 0vw 0vw;
}
