.tableFilter{
    margin: 2vh 0vw;
}
.tableFilter > div{
    display: contents;
}
.tableFilterOption > span{
    cursor: pointer;
    margin: 0vh 0.2vw;
    padding: 0vh 1vw;
}
.tableFilterOption.selected > span{
    border: solid;
    border-width: 1px;
    border-radius: 20px;
}
.tableContainer{
    border-bottom: 1vh solid rgba(240, 243, 248, 0.72);
    margin: 1.5vh 0.2vw;
    border-radius: 5px;
}
.userResultsTable{
    padding: 0px 0px !important;
    margin: 0vh 0vw;
    color: rgba(240, 243, 248, 0.96);

    border-collapse: collapse;
    font-size: 1vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    overflow: hidden;
    border-radius: 20px;
    border-bottom-right-radius: 0px;

    --bs-table-hover-color: rgba(240, 243, 248, 0.96);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.106);
}
.userResultsTable thead tr {
    background-color: rgba(240, 243, 248, 0.72);
    color: rgba(5, 45, 91, 0.727);
    text-align: left;
}
.userResultsTable th,
.userResultsTable td {
    padding: 1.5vh 1.5vw;
    text-align: center;
}
.userResultsTable tbody tr {
    border-bottom: thin solid #dddddd;
    color: rgba(240, 243, 248, 0.96);
}
.userResultsTable tbody tr:nth-of-type(even) {
    background-color: rgba(240, 243, 248, 0.02);
}
.table-striped>tbody>tr:nth-of-type(odd)>*{
    background-color: rgba(240, 243, 248, 0.06);
    color: rgba(240, 243, 248, 0.96) !important;
}

/* Table scrollable vertically */
.userResultsTable table {
    display: flex;
    flex-flow: column;
    width: 100%;
}
.userResultsTable thead {
    flex: 0 0 auto;
}
.userResultsTable tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 45vh;
}
.userResultsTable tr {
    width: 100%;
    display: table;
    table-layout: fixed;
}
.openResultIcon{
    font-size: 1.5vw;
    cursor: pointer;
}

/* Table Scrollbar*/
.userResultsTable tbody::-webkit-scrollbar {
    width: 0.8vw;
}

.userResultsTable tbody::-webkit-scrollbar-thumb {
    background: rgba(240, 243, 248, 0.875);
    border-radius: 0px;
}

.userResultsTable tbody::-webkit-scrollbar-track {
    background: #052344;
    border-radius: 0px;
}


/* SMALL SCREENS */
@media (max-width: 575px) {
    .userResultsTable.statsTable{
        width: calc(100% - 4vw);
        margin: 0vh 2vw;
        font-size: 2vw;
    }
    .openResultIcon{
        font-size: 3.5vw;
    }
    .tableFilter{
        font-size: 4vw;
    }
}