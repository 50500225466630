.tooltip-box{
    position: absolute;
    width: 32vw;
    top: 108%;
    left: -150%;
    z-index: 10;

    background-color: #175ba8c7;
    border-radius: 10px;
   
    padding: 1vw 2.5vw 1vw 1.5vw;

    text-align: justify;
}
.transparent{
    opacity: 0;
    transition: opacity 0.8s;
}
.fadeIn{
    opacity: 1;
    transition: opacity 0.8s;
}

.tooltip-box::after{
    position: absolute;
    top: -12%;
    right: 5%;
    content: "";
    display: block;
    border-bottom: 1.2vw solid #175ba8c7;
    border-left: 1.2vw solid transparent;
    border-right: 1.2vw solid transparent;
}

#tooltip-close-btn{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 5%;
    margin-right: 1%;
}



/* SMALL SCREENS */
@media (max-width: 575px) {
    #xs-tooltip{
        left: auto;
        right: 0% !important;
        top: 100%;
        
        width: fit-content;
        font-size: 4.2vw;
        padding: 0.5vh 5vw;
    }
    .tooltip-box::after{
        top: -8%;
        border-bottom: 3vw solid #175aa6c7;
        border-left: 3vw solid transparent;
        border-right: 3vw solid transparent;
    }
    #tooltip-close-btn{
        cursor: pointer;
        position: absolute;
        font-size: 6vw;
        right: 0;
        top: 10%;
        margin-right: 3%;
    }
    #xs-tooltip-msg{
        position: relative;
        top: 0.4vh;
    }
}