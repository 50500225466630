.resetPassword{
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 6vw;
    bottom: 0;
    width: 100%;

    padding: 0vh 4vw;

    font-size: 1.2vw;
    color: rgb(240, 243, 248);

    font-family: 'Titillium Web', sans-serif;
}

.changePasswordIcon{
    font-size: 8vw;
}

.pwdErrorText{
    margin-top: 1vh;
    text-align: left;
    font-weight: 600;
    color: red;
    font-size: 1vw;
}



/* SMALL SCREENS */
@media (max-width: 575px) {
    .resetPasswordInput{
        font-size: 5vw;
    }
    .changePasswordIcon{
        font-size: 15vw !important;
    }
}