html,
body {
  height: 100%;
  margin: 0;

  /*overflow: hidden;*/

  background-image: url("../src/img/background-compressed.jpg");
  background-size: cover;

  font-family: 'Titillium Web', sans-serif;
}

.App {
  text-align: center;
}

p{
  text-align: justify;  
}

.container{
  max-width: none !important;
}

.textCenter{
  text-align: center;
}
.hCenter{
  margin: 0 auto;
}
.hCenterFlex{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.centeredRow{
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.vCenter{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hCenterRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.averagesCol{
  text-align: center;
  font-size: 0.9vw;
}
.noPadding{
  padding: 0px 0px;
}
.italic{
  font-style: italic;
}
.underlined{
  text-decoration: underline;
}
.link{
  text-decoration: underline;
  cursor: pointer;
}
.textLeft{
  text-align: left;
}

.waterMark{

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;

  font-size: 0.8vw;
  padding: 0.1vw 1vw;

  /*border-style: solid;
  border-width: 1px 0px 1px 0px;
  border-color: rgb(240, 243, 248);
  border-bottom-color: rgb(60, 75, 117);*/

  color: #000000;
  font-weight: 600;
  filter: drop-shadow(-0px 2px 15px #000);
}
.waterMarkRight{
  float: right;
}
.waterMarkLeft{
  float: left;
}
.flag{
  width: 1vw;
  padding-bottom: 0.5vh;
}
.backBtn{
  position: fixed;
}



/* SMALL SCREENS */
@media (max-width: 575px) {
  .xs-nopadding{
      padding: 0px !important;
  }
  .xs-nomargin{
      margin: 0px !important;
  }
  .styledBox{
      border-bottom-width: 1px !important;
      filter: drop-shadow(0px 2px 1px #000) !important;
  }
  .waterMark{
    display: none;
  }
}


/* PC SCREENS */
@media (min-width: 576px) {
  .sm-nopadding{
    padding: 0px !important;
  }
}