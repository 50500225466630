.lensSelector{
    height: 100%;
}
.lensSelectorBox > .row{
    margin: 2vh 7.5%;
    max-height: 60vh;
    overflow-y: auto;
}
.lensSelectorTitle{
    text-align: center;
    font-size: 1.5vw;
    font-weight: 400;
}
.lensSelectorFlexbox{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1vw 5vw;
}
.lensSelectorFlexbox > div{
    flex-basis: 25%;
}
.lensOption{
    border-style: solid;
    border-width: 1px;
    border-color: rgba(240, 243, 248, 0.924);
    border-radius: 5px;

    padding: 0vw 0.2vw;
}
.lensOption:hover{
    background-color: #f5f5f505;
}
.lensImg{
    opacity: 0.924;
    cursor: pointer;
}
.lensLabel{
    text-align: center;
    padding-top: 0.8vh;
}
.lensHelpSpan{
    text-align: right;
    font-size: 1vw;
    font-style: italic;
}

.hint4{
    font-size: 1.3vw;
    font-weight: 400;
    cursor: pointer;
    color: rgb(255, 255, 255);
}
.hint4:hover .hintText4 {
    display: inline-block;
}
.hintText4{
    background-color: rgba(240, 243, 248, 0.781);
    border-color: #094fa0c2;
    border-style: solid;
    border-width: 1px;
    border-radius: 2vw;

    color: #094fa0f5;
    font-size: 0.8vw;
    text-align: left;

    position: absolute;
    top: -250%;
    margin-left: 0.25vw;
    z-index: 2000;
    padding: 0.8vw;

    display: none;
}
.hint4Img{
    width: 38vw;
    border-radius: 15px;
}
.hint5Img{
    width: 20vw;
    border-radius: 15px;
}

/* SMALL SCREENS */
@media (max-width: 575px) {
    .lensSelector{
        width: 90% !important;
    }
    .lensSelectorFlexbox{
        overflow-y: auto;
    }

    .lensSelectorTitle{
        font-size: 5vw;
    }
    .lensHelpSpan{
        font-size: 2vw;
    }

    .lensImg{
        width: 40vw !important;
    }
    .lensLabel{
        font-size: 5vw !important;
    }
    .hint4{
        display: none;
    }
}