.disclaimerOn{
    background-color: #094fa0c7;
    filter: drop-shadow(-9px 5px 16px #000);

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 4;
    
    padding: 0vh 12.5vw;
    font-size: 1.2vw;
    color: rgb(240, 243, 248);

    opacity: 1;
    -webkit-transition: opacity 0.8s ease-in;
    -moz-transition: opacity 0.8s ease-in;
    -o-transition: opacity 0.8s ease-in;
    -ms-transition: opacity 0.8s ease-in;
    transition: opacity 0.8s ease-in;
}
.disclaimerOff{
    background-color: #094fa0c7;
    filter: drop-shadow(-9px 5px 16px #000);

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 4;
    
    padding: 0vh 12.5vw;
    font-size: 1.2vw;
    color: rgb(240, 243, 248);

    opacity: 0;
    -webkit-transition: opacity 0.25s ease-in;
    -moz-transition: opacity 0.25s ease-in;
    -o-transition: opacity 0.25s ease-in;
    -ms-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
}

.discTitle{
    font-size: 3vw;
    font-weight: 600;
}
.discIcon{
    position: relative;
    bottom: 1vh;
    left: 0.8vw;
}
#toricLogo{
    width: 8vw;
}
.discButton{
    color: rgb(240, 243, 248);
    background-color: rgba(0, 128, 0, 0.295);

    padding: 1vh 0vw;
    margin: 0vw 31vw;

    cursor: pointer;
}
.stopShowingText{
    cursor: pointer;
    text-decoration: underline;
    font-style: italic;
    margin-top: 1vh;
}

/* SMALL SCREENS */
@media (max-width: 575px) {
    .disclaimerOn, .disclaimerOff{
        font-size: 3vw;
    }
    .discTitle{
        font-size: 8vw;
    }
    #toricLogo{
        width: 20vw;
    }
    .discIcon{
        position: relative;
        bottom: 0.5vh;
        left: 0.8vw;
    }
    .discButton{
        color: rgb(240, 243, 248);
        background-color: rgba(0, 128, 0, 0.295);
    
        padding: 1.5vh 5vw;
        font-size: 4vw;
        font-weight: 600;
        margin: 0vw 10vw;
    
        cursor: pointer;
    }
}