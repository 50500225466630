.downloading, .downloadingOverlay{
    font-size: 1.1vw;
    background-color: rgba(0,0,0,0.6);

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;

    z-index: 5;
}
.downloadingContent{
    width: 50% !important;

    color: #f0f3f8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 1vw 5vw;

    z-index: 6;
    text-align: center;
}