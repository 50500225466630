.popup{
    font-size: 1.1vw;
    background-color: rgba(0, 0, 0, 0.35);

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 15;
}

.popupContent{
    color: #f0f3f8;

    width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2vw 3.5vw;
    z-index: 15;
}

.popupIcon{
    font-size: 1.8vw;
    color: rgb(206, 211, 215);
}
.popupTitle{
    font-size: 1.3vw;
    font-weight: 600;
    text-align: start;
}
.popupMessage{
    font-size: 1.1vw;
    margin: 1vh 0vw;
    text-align: start;
}
.popupBtn{
    cursor: pointer;
    width: 25%;
    text-align: center;

    border-style: solid;
    border-color: #f0f3f8;
    border-width: 1px;
    border-radius: 5vw;
    background-color: #f0f3f809;
    
    margin: 0 auto;
    margin-top: 2vh;
    padding: 1vh;
}
.popupBtn:hover{
    background-color: #f0f3f81a;
}



/* SMALL SCREENS */
@media (max-width: 575px) {
    .popupContent{
        padding: 2vh 5vw !important;
        width: 90% !important;
    }
    .popupTitle, .popupIcon{
        font-size: 8vw;
    }
    .popupMessage{
        font-size: 5vw;
    }
    .popupBtn{
        width: 50%;
        padding: 2vh 5vw !important;
        font-size: 5vw;
    }
}