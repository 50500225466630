.result, .overlay{
    font-size: 1.1vw;
    background-color: rgba(0,0,0,0.6);

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;

    text-align: center;
}

.orientationSubtitle{
    margin-top: -0.8vh !important;
    line-height: 1.5vh;
    font-style: italic;
    font-size: 0.8vw;
}
.resultOrientationRow{
    position: relative;
    padding: 0% 25%;
    margin: 0 auto;
}
#orientationSpan{
    font-weight: 400 !important;

    border-style: solid;
    border-color: rgba(240, 243, 248, 0.116);
    border-width: 1px;
    border-radius: 10px;

    padding: 0.1vw 0.5vw 0.1vw 0.8vw;
    margin: 0vw auto;

    background-color: #094fa08f;
}
.eyeGraphic{
    position: relative;
    right: 0.8%;
    width: 60%;
    margin: 1.6vh auto 1.9vh auto;

    display: flex;
    justify-content: center;
    align-items: center;
}
#graphicCircle{
    width: 100%;
}
#graphicIOL{
    position: absolute;
    left: 6.9vw;
    width: 30%;
}
#graphicSuggested{
    position: absolute;
    left: 6.9vw;
    margin: auto 0;
    width: 30%;
    transform: rotate(82deg);
}

.rangeDiv{
    position: relative;

    margin-bottom: 0.15vw;

    height: 3vh;
}
#orientationRange{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
#rangeArrowL, #rangeArrowR{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    cursor: pointer;

    font-size: 0.8vw;
}
#rangeArrowL{
    left: -1vw;
}
#rangeArrowR{
    right: -1vw;
}

.identifiersDiv{
    padding: 4vh 0vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    
    font-size: 1.5vw;
    font-style: italic;
}

.hint{
    font-weight: 400;
    padding: 0 0 2vw 0.5vw;
    cursor: pointer;
}
.hint:hover .hintText {
    display: inline-block;
}
.hintText, .hintText2{
    background-color: rgba(240, 243, 248, 0.781);
    border-color: #094fa0c2;
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;

    color: #094fa0f5;
    font-size: 0.8vw;
    text-align: left;

    position: absolute;
    width: 42.5%;
    top: 0;
    margin-left: 0.25vw;
    z-index: 2000;
    padding: 0.8vw;

    display: none;
}

.hint2:hover .hintText {
    display: inline-block;
}
.hint2{
    cursor: pointer;
    position: absolute;
    right: 18%;

    width: min-content;
}
.hintText2{
    width: 13.5vw;
}

/* loading */
.resultLoadingDiv{
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}