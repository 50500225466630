.postopData{
    max-width: 100vw;
    height: 100%;
    padding: 2vw 0vw 1vw 0vw;
    font-family: 'Titillium Web', sans-serif;

    display: flex;
    flex-direction: column;
    align-content: center;

    position: relative;
}
.w-30{
    width: 30% !important;
}


.textCol{
    white-space: nowrap;
    width: 11.5vw;
}
.averageRow{
    width: 100% !important;
    text-align: center;
}
.centeredCol{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.helpLabel{
    padding-top: 1vh;

    text-align: center;
    font-size: 0.8vw;
    font-weight: 400;
}
.postopRefRow{
    margin: 0 auto;
}

.deleteMeasureSpan2{
    position: absolute;
    text-align: right;

    z-index: 5;

    padding-right: 2.4vw;
    padding-top: 0vh;

    font-size: 1.5vw;
}

.backBtn{
    cursor: pointer;

    position: absolute;
    margin-left: -0.8vw;
    margin-top: 0.4vw;

    width: max-content;

    padding: 1vw !important;
}

.bigBlock3{
    padding: 1.3vw 2vw !important;
}
.bigBlock4{
    width: 75vw;
    margin: 1vw 0vw;

    max-height: 33vh;
}
@media (max-width: 1280px) {
    .bigBlock4{
        margin: 4vw 0vw;
    }
}
@media (max-width: 845px) {
    .bigBlock4{
        margin: 10vw 0vw;
    }
}

.octForm{
    width: 37.5% !important;
    padding: 0px 1vw !important;
}

.calculateBtn{
    padding: 1.2vw 2.8vw !important;
    margin: 0 auto;

    background-color: #094fa0aa;
    
    color: rgb(240, 243, 248);
    font-weight: 600;
}
.calculateBtnDisabled{
    background-color: #5178a5cb;
    color: rgb(164, 175, 196);

    padding: 1.2vw 2.8vw !important;
    margin: 0 auto;
}
.calculateBtn:hover, .backBtn:hover {
    color: white;
    background-color: #094fa0cb;
    filter: drop-shadow(-5px 10px 10px #000);
}
.calculatorIcon{
    margin: 0.4vw 1.4vw;
    font-size: 3vw;
}

.calculateCol{
    display: flex;
    flex-direction: column;
    justify-content: center;
}