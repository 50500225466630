.tca-delete-measure-span{
    position: absolute;
    text-align: right;

    z-index: 5;

    padding-right: 0vw;
    padding-top: 0vh;

    font-size: 1.5vw;
}

.tca-title{
    font-weight: 600;
    text-align: left;
}

.tca-hint{
    font-size: 1.2vw;
    font-weight: 400;
    cursor: pointer;
    color: rgb(255, 255, 255);

    position: relative;
    bottom: 1px;
}
.tca-hint:hover .tca-hint-text {
    display: inline-block;
}
.tca-hint-text{
    background-color: rgba(240, 243, 248, 0.95);
    border-color: #094fa0c2;
    border-style: solid;
    border-width: 1px;
    border-radius: 2vw;

    color: #094fa0f5;
    font-size: 0.8vw;
    text-align: left;

    position: absolute;
    margin-left: 0.15vw;
    z-index: 8;
    padding: 0.8vw;

    display: none;
}

/* SMALL SCREENS */
@media (max-width: 575px) {
    .tca-delete-measure-span{
        position: absolute;
        text-align: right;
        z-index: 2;
        height: 6vh;
        line-height: 6vh;
        font-size: 5vw !important;
        right: 1.5vw;
    }
}