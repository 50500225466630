@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@300;400&display=swap');

.aboutUs{
    position:absolute;
    top: 6vw;
    bottom: 0;

    background-color: #06284fdc;
    padding: 2vw 6vw;

    overflow-y: auto;

    font-family: 'Anek Telugu', sans-serif;
}

.aboutUs::-webkit-scrollbar {
    width: 0.8vw;
    left: -50;
}

.aboutUs::-webkit-scrollbar-thumb {
    background: rgba(240, 243, 248, 0.875);
    border-radius: 20px;
}

.aboutUs::-webkit-scrollbar-track {
    background: #052344;
    border-radius: 20px;
}

.aboutTitle{
    color: rgb(240, 243, 248);
    position: relative;
    margin-bottom: 1vh;

    font-size: 2.4vw;
    font-weight: 600;

    display: flex;
    flex-direction: row;
    justify-content: center;
}
.aboutTitleIcon{
    margin-bottom: 3%;
    padding-right: 1%;
}

.aboutUsText{
    color: rgb(240, 243, 248);
    font-size: 1.2vw;
    font-weight: 300;
    text-align: justify;
    text-justify: inter-word;
    white-space: 1vh;
}

.aboutIcon{
    margin-top: 0.67%;
}

.about-link{
    color: #a2b9d2;
}



/* SMALL SCREENS */
@media (max-width: 575px) {
    .aboutTitle{
        font-size: 7vw;
        font-weight: 600;
    }
    .aboutTitleIcon{
        margin-bottom: 3%;
        padding-right: 1%;
    }
    .aboutUs{
        top: 10vh;
        padding: 5vh 0vw 2vh 0vw;
        max-width: 100vw;
        overflow-x: hidden;
    }
    .aboutUsText{
        font-size: 4vw;
        white-space: 0.2vh !important;
        padding: 0px 5vw;
    }
}