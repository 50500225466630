.preopData{
    height: 100%;
    padding: 2vw 0vw;
    font-family: 'Titillium Web', sans-serif;

    display: flex;
    flex-direction: column;
    align-content: center;

    position: relative;
}

.nameInput{
    max-width: 30vw !important;
}
.patientNameDiv{
    margin-left: auto;
    margin-right: 0;
    
    white-space: nowrap;
}

.continueBtn{
    padding: 1.2vw 2.4vw !important;
    margin: 0 auto;

    background-color: #094fa0aa;
    
    color: rgb(240, 243, 248);
    font-weight: 600;
}
.continueBtn:hover{
    color: white;
    background-color: #094fa0cb;
    filter: drop-shadow(-5px 10px 10px #000);
}
.continueBtnDisabled{
    background-color: #5178a5cb !important;
    color: rgb(164, 175, 196);

    padding: 1.2vw 2.4vw !important;
    margin: 0 auto;
}
.arrowIcon{
    align-self: center;   
    margin: 0.5vw 0.5vw;
    font-size: 1.8vw;
}

.block3{
    padding: 0vw 1vw !important;
}
.block4{
    max-width: 12.3333333333334%;
    padding-left: 0vw;
    margin: 0vw 1vw 0vw 0vw;
}

.hint3{
    cursor: pointer;
    position: absolute;
    left: calc(100% - 0.8vw);
    height: 100%;
    padding: 0 !important;

    width: min-content;
    z-index: 3 !important;
}
.hint3:hover .hintText {
    display: inline-block;
}
.hintText3{
    position:absolute;
    width: 34.8vw;
    z-index: 2;
}
.hint3Img{
    width: 33vw;
    border-radius: 15px;
}
.hint3Img{
    width: 33vw;
    border-radius: 15px;
}

.hint6{
    font-size: 1.3vw;
    font-weight: 400;
    cursor: pointer;
    color: rgb(255, 255, 255);
}
.hint6:hover .hintText6 {
    display: inline-block;
}
.hintText6{
    background-color: rgba(240, 243, 248, 0.781);
    border-color: #094fa0c2;
    border-style: solid;
    border-width: 1px;
    border-radius: 2vw;

    color: #094fa0f5;
    font-size: 0.8vw;
    text-align: left;

    position: absolute;
    margin-left: 0.15vw;
    z-index: 8;
    padding: 0.8vw;

    display: none;
}

/* SMALL SCREENS */
@media (max-width: 575px) {
    .preopData{
        overflow-x: visible;
    }   
    .preopMeasures{
        padding: 0vw 5vw;
    }

    .block1{
        max-width: none;
        margin: 0vw 0vw 0vw 0vw;
    }

    .bigBlock{
        margin: 0vw 0vw;
        z-index: 1;
    }

    #st-box1{
        display: block;
    }
    #box-icon{
        min-width: 100% !important;
        height: 20vh;
        margin: 3vh 0px !important;
    }

    .nameInput{
        max-width: 100vw !important;
    }

    .patientDataForm{
        padding: 0vh 4vw 1vh 4vw;
    }
    .patientDataForm > .row{
        margin-top: 0.4vh;
        margin-bottom: 0.4vh;
        padding-left: 0vw;
    
        text-align: left;
    }

    .eyeInput{
        margin-left: 0.4vw;
        
        line-height: normal;
    }

    .bigBlock2{
        height: fit-content !important;
        position: relative;
        display: block;

        margin-left: 0px;
        width: 100% !important;
    }

    #st-box2{
        margin-top: 2vh;
    }

    #cornea-icon{
        max-width: none;
    }
    #st-measurements-ka{
        max-width: 90%;
        margin: 1vh auto;
    }
    #st-box2-tca{
        margin: 1vh auto;;
        max-width: 90%;
    }
    /* TCA HINT*/
    .hint6{
        font-size: 3vw;
        font-weight: 400;
        cursor: pointer;
        color: rgb(255, 255, 255);
    }
    .hint6:hover .hintText6 {
        display: inline-block;
    }
    .hintText6{
        background-color: rgba(240, 243, 248, 0.781);
        border-color: #094fa0c2;
        border-style: solid;
        border-width: 1px;
        border-radius: 2vw;
    
        color: #094fa0f5;
        font-size: 2vw;
        text-align: left;
    
        position: absolute;
        margin-left: 0.15vw;
        z-index: 86;
        padding: 0.8vw;
    
        display: none;
    }

    #pb-patientdata{
        margin-bottom: 5vh !important;
    }
    #pb-preop-measurements > .formCol{
        margin-bottom: 2vh !important;
    }

    /* Continue btn */ 
    .continueBtn{
        width: 100% !important;
        padding: 4vh 2.4vw !important;
        margin: 2vh auto;
        font-size: 5vw;
    
        background-color: #094fa0aa;
        
        color: rgb(240, 243, 248);
        font-weight: 600;
    }
    .continueBtn:hover{
        color: white;
        background-color: #094fa0cb;
        filter: drop-shadow(-5px 10px 10px #000);
    }
    .continueBtnDisabled{
        width: 100% !important;
        background-color: #5178a5cb !important;
        color: rgb(164, 175, 196);
    
        padding: 4vh 2.4vw !important;
        margin: 2vh auto;
        font-size: 5vw;
    }
    .arrowIcon{
        font-size: 5vw;
    }
}


/* PC SCREENS */
@media (min-width: 576px) {
    .block1{
        max-width: 16.666666666667%;
        margin: 0vw 0vw 0vw 2.75vw;
    }

    .bigBlock{
        max-height: 14.5vw !important;
    
        padding: 1.5vw 0vw !important;
        margin: 0vw 0vw;
        z-index: 1;
    }
    
    .patientDataForm{
        padding: 0vw 2vw;
    }
    .patientDataForm > .row{
        margin-top: 0.4vw;
        margin-bottom: 0.4vw;
        padding-left: 0vw;
    
        text-align: left;
    }

    .eyeInput{
        margin-left: 0.4vw;
        width: 100% !important;
    }

    .bigBlock2{
        max-width: 98%;
        height: 14.5vw !important;
    
        margin-left: 0.7vw;
    
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .bigBlock2 > .formCol{
        padding: 0vw 0.5vw;
    }
}

