#sia-data{
    padding-right: 2vw;
}
.sia-title{
    position: relative;
    line-height: 10%;
    height: 10%;

    color: #094fa0ea;
    text-shadow: 2px 2px 4px #484168;

    margin-bottom: 2vh !important;

    /*TEXT SHADOW PRESET*/
    text-shadow: 
        -1px -1px 0px rgb(240, 243, 248),
         0px -1px 0px rgb(240, 243, 248),
         1px -1px 0px rgb(240, 243, 248),
        -1px  0px 0px rgb(240, 243, 248),
         1px  0px 0px rgb(240, 243, 248),
        -1px  1px 0px rgb(240, 243, 248),
         0px  1px 0px rgb(240, 243, 248),
         1px  1px 0px rgb(240, 243, 248);

    filter: drop-shadow(-9px 8px 16px rgba(0, 0, 0, 0.74));
}

.sia-input-section{
    margin: 3vh 0vh;
}

.sia-calculateBtn-disabled{
    background-color: #5178a5cb;
    color: rgb(164, 175, 196);

    width: 70% !important;
    padding: 8vh 1vw !important;
    margin: 0 auto;
}

.sia-calculateBtn-enabled{
    background-color: #094fa0aa;
    color: rgb(240, 243, 248);
    font-weight: 600;

    width: 70% !important;
    padding: 8vh 1vw !important;
    margin: 0 auto;
}
.sia-calculateBtn-enabled:hover{
    color: white;
    background-color: #094fa0cb;
    filter: drop-shadow(-5px 10px 10px #000);
}

.sia-add-patient-btn{
    text-align: center;
    
    width: 70%;
    margin: 0 auto;
    margin-bottom: 2.5vh;
    padding: 1.5vh 0vw;
    border: #094fa0cb 1px solid;
    border-radius: 20px;
}
.sia-add-patient-btn.enabled:hover{
    background-color: rgba(255, 255, 255, 0.966);
    cursor: pointer;
    filter: drop-shadow(0px 5px 16px #094fa056);
}
.sia-add-patient-btn.enabled{
    color: #094fa0;
    background-color: rgba(255, 255, 255, 0.877);
}
.sia-add-patient-btn.disabled{
    cursor: default;
    color: #094fa0b7;
    background-color: rgba(255, 255, 255, 0.322);
}

.sia-result, .sia-overlay{
    z-index: 2;
    font-size: 1.1vw;
    background-color: rgba(0,0,0,0.6);

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    
    text-align: center;
}

.sia-result-title{
    margin-top: 2vh;
    margin-left: 0.5vw;
    margin-bottom: 0.5vh;
    font-size: 1.8vw;
    font-weight: 600;
    text-align: center;
}
.sia-result-content{
    z-index: 2;
    width: 50% !important;

    font-family: 'Titillium Web', sans-serif !important;
    color: #f0f3f8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 1vw 5vw;

    z-index: 4;
}
.sia-results-box{
    margin: 4vh 0vw 1vh 0vw;
}

.sia-results{
    margin-bottom: 2vh;
}
#results{
    margin: 0.5vh 0vh;
    margin-left: 0.5vw;
    font-size: 1.3vw;
}

.sia-results-table-container{
    border-bottom: 1vh solid rgba(240, 243, 248, 0.72);
    margin: 1.5vh 0.2vw;
    border-radius: 20px;
}
.sia-results-table{
    width: 75%;
    padding: 0px 0px !important;
    margin: 2vh auto;
    color: rgba(240, 243, 248, 0.96);

    overflow: hidden;
    border-collapse: separate !important;
    font-size: 1vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    border-style: solid;
    border-width: 1px;
    border-color: #f0f3f8;
    border-radius: 20px;

    --bs-table-hover-color: rgba(240, 243, 248, 0.96);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.106);
}
.sia-results-table thead tr {
    background-color: rgba(240, 243, 248, 0.72);
    color: rgba(5, 45, 91, 0.727);
    text-align: left;
    border-collapse: separate !important;
    border-radius: 20px !important;
}
.sia-results-table th,
.sia-results-table td {
    padding: 1.5vh 1.5vw;
    text-align: left;
}
.sia-results-table tbody tr {
    border-bottom: thin solid #dddddd;
    color: rgba(240, 243, 248, 0.96);
}
.sia-results-table tbody tr:nth-of-type(even) {
    background-color: rgba(240, 243, 248, 0.02);
}
.table-striped>tbody>tr:nth-of-type(odd)>*{
    background-color: rgba(240, 243, 248, 0.06);
    color: rgba(240, 243, 248, 0.96) !important;
}

.sia-result-options{
    margin: 0.8vh 0vh;
    margin-bottom: 2vh;
}
.sia-result-options > span{
    cursor: pointer;
    margin: 0 0.35vw;
}
.sia-result-options > span:hover{
    text-decoration: underline;
}



/* SMALL SCREENS */
@media (max-width: 768px) and (orientation: portrait) {
    #sia-container{
        width: 90% !important;
    }
    #sia-input{
        min-height: fit-content;
        margin-top: 4vh;
        margin-bottom: 5vh;
    }
    #sia-tca-row > div{
        margin: 1vh 0vw;
    }

    #sia-calculate-col{
        margin: 7vh 0vw 5vh 0vw;
    }
    #sia-calculate-col > div > span, 
    #sia-calculate-col > div > button{
        font-size: 5vw;
        width: 100% !important;
    }
    .sia-calculateBtn-enabled, .sia-calculateBtn-disabled{
        font-size: 7vw !important;
    }
    #sia-addedpatients{
        font-size: 5vw !important;
    }


    /* RESULT */
    .sia-result-content{
        width: 90% !important;
    }
    .sia-result-title{
        font-size: 5vw;
    }
    #results{
        font-size: 5vw !important;
    }
    .sia-result-options > span{
        margin: 0vh 1vw;
        font-size: 3vw;
    }
    .sia-results-table{
        font-size: 5vw;
        width: 100%;
        margin: 2vh 0vw !important;
    }
}