.postopData{
    max-width: 100vw;
    height: 100%;
    padding: 2vw 0vw 1vw 0vw;
    font-family: 'Titillium Web', sans-serif;

    display: flex;
    flex-direction: column;
    align-content: center;

    position: relative;
}
.w-30{
    width: 30% !important;
}

.postopMeasurements{
    margin-top: 4vh;
}


.textCol{
    white-space: nowrap;
    width: 11.5vw;
}
.averageRow{
    width: 100% !important;
    text-align: center;
}
.centeredCol{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.helpLabel{
    padding-top: 1vh;

    text-align: center;
    font-size: 0.8vw;
    font-weight: 400;
}
.postopRefRow{
    margin: 0.5vh auto;
}

.deleteMeasureSpan4{
    position: absolute;
    text-align: right;

    z-index: 5;

    padding-right: 1.4vw;
    padding-top: 0vh;

    font-size: 1.5vw;
}

.backBtn{
    cursor: pointer;

    position: absolute;
    margin-left: -1.5vw;
    margin-top: 0.4vw;

    width: max-content;

    padding: 0.8vw !important;
    z-index: 1;
}
.refreshBtn{
    cursor: pointer;

    position: absolute;
    margin-right: -1.4vw;
    margin-top: 0.6vw;
    right: 0;

    width: max-content;

    padding: 0.4vw 0.6vw 0.5vw 0.6vw !important;
    z-index: 1;

    font-size: 1.7vw;
}

.bigBlock3{
    padding: 1.3vw 2vw !important;
}
.bigBlock4{
    width: 75vw;
    margin: 1vw 0vw;

    max-height: 33vh;
}
@media (max-width: 1280px) {
    .bigBlock4{
        margin: 4vw 0vw;
    }
}
@media (max-width: 845px) {
    .bigBlock4{
        margin: 10vw 0vw;
    }
}
@media (max-width: 600px) {
    .bigBlock4{
        margin: 2vh 0vw;
    }
}

.octForm{
    width: 37.5% !important;
    padding: 0px 1vw !important;
}

.calculateBtn2{
    padding: 7vw 4vw !important;
    margin: 0 auto;

    background-color: #094fa0aa;
    
    color: rgb(240, 243, 248);
    font-weight: 600;
    font-size: 1.5vw;
}
.calculateBtnDisabled2{
    background-color: #5178a5cb !important;
    color: rgb(164, 175, 196);
    font-size: 1.5vw;

    padding: 7vw 4vw !important;
    margin: 0 auto;
}
.calculateBtn:hover, .backBtn:hover, .refreshBtn:hover {
    color: white;
    background-color: #094fa0cb;
    filter: drop-shadow(-4px 10px 10px #000);
}
.calculatorIcon{
    margin: 0.4vw 1.4vw;
    font-size: 3vw;
}

.calculateCol{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* SMALL SCREENS */
@media (max-width: 575px) {
    #st-postop-title{
        min-height: fit-content !important;
        padding: 3vh 0vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 0px;
    }
    #st-postop-title > h1{
        display: inline-block;
        vertical-align: middle;
    }

    #st-box3-col{
        margin: 0vh 0vw !important;
    }
    #st-iol-mfact, #st-iol-model{
        height: 5vh;
        line-height: normal;
    }

    .postopRefRow{
        padding: 0 !important;
    }
    
    #st-box4{
        height: auto !important;
        max-height: none !important;

        padding: 2vh 0vw;
    }
    .octForm{
        width: 90% !important;
        padding: 0vh 2vw !important;
        margin: 2vh 0vw;
    }

    #pb-postop-ref{
        margin-bottom: 2vh;
    }
    #pb-postop-measures-icon{
        min-width: 100% !important;
        height: 20vh;
        margin: 3vh 0px 0vh 0px !important;
    }

    .calculateBtn, .calculateBtn2{
        width: 100% !important;
        padding: 4vh 2.4vw !important;
        margin: 2vh auto;
    
        background-color: #094fa0aa;
        
        color: rgb(240, 243, 248);
        font-weight: 600;
        font-size: 5vw;
    }
    .calculateBtn:hover{
        color: white;
        background-color: #094fa0cb;
        filter: drop-shadow(-5px 10px 10px #000);
    }
    .calculateBtnDisabled, .calculateBtnDisabled2{
        width: 100% !important;
        background-color: #5178a5cb !important;
        color: rgb(164, 175, 196);
    
        padding: 4vh 2.4vw !important;
        margin: 2vh auto;
        font-size: 5vw;
    }
    .calculatorIcon{
        font-size: 10vw;
    }
    .backBtn{
        cursor: pointer;
    
        position: absolute;
        top: 1vh;
        left: -8vw !important;
        
        font-size: 5vw;
        width: 10vw;

        display: flex;
        flex-direction: row;
        justify-content: center;
    
        padding: 1.5vh 0vw !important;
        z-index: 1;
    }
}