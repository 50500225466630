.login{
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 6vw;
    bottom: 0;
    width: 100%;

    padding: 0vh 4vw;

    font-size: 1.2vw;
    color: rgb(240, 243, 248);

    font-family: 'Titillium Web', sans-serif;
}

.loginContainer{
    padding: 4vh 1vw;
    width: 60%;
}

.loginMessage{
    font-size: 0.9vw;
    margin: 2vh 1vw;
}

.loginFormCol{
    padding-right: 2vw;
}
.loginFormGroup{
    margin-bottom: 2vh;
}
.loginFormGroup > .row{
    margin: 1vh 0vw;
}

.loginInput{
    color: #062e5b;
    font-size: 1vw;
}

.suggestionSpan{
    position: absolute;
    right: 0;
    margin-right: 1.2vw;

    font-size: 0.9vw;
    color: rgba(240, 243, 248, 0.879) !important;
}
.suggestionSpan > a{
    color: rgba(240, 243, 248, 0.879) !important;
}


/* SMALL SCREENS */
@media (max-width: 575px) {
    .login{
        top: 10vh;
        min-height: 90vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .loginContainer{
        width: 90%;
        font-size: 5vw;
    }
    .loginInput{
        font-size: 5vw;
        margin-bottom: 0.5vh;
    }
    .loginMessage{
        font-size: 4vw;
    }
}