.saveLoading{
    margin: 0 auto;
}

.saveTitle{
    font-weight: 600;
    font-size: 1.5vw;
    display: flex;
    align-items:center;

    margin-bottom: 1vh;
}
.saveMsg{
    text-align: justify;
    margin-bottom: 1vh;
}
.saveForm{
    margin-top: 1vh;
    margin-bottom: 2vh;
}
.saveForm > .form-control{
    margin: 0.5vh 0vh !important;
}
.saveResultInput{
    margin: 0.5vh 0vw;
}


/* SMALL SCREENS */
@media (max-width: 575px) {
    .saveTitle{
        font-size: 8vw;
    }
    .saveMsg{
        font-size: 5vw;
    }
}