.userPageContent{
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 6vw;
    bottom: 0;
    width: 100%;

    padding: 0vh 4vw;

    font-size: 1.2vw;
    color: rgb(240, 243, 248);

    font-family: 'Titillium Web', sans-serif;
}

.userContainer{
    margin: 0 auto;
    padding: 5vh 3vw 3vh 3vw;
    width: 100%;
    max-height: 90%;
}

.userHeader{
    font-size: 1.8vw;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
    
    display: flex;
    flex-direction: row;

    width: 100%;
    height: min-content;
}
.userTitle{
    width: 100%;
    margin-bottom: 0.7vh;
}
.userTitleIcon{
    position: relative;
    bottom: 0.4vh;
    margin-right: 0.4vw;
    font-size: 3vw;
}
.logout{
    font-size: 1.2vw;
    vertical-align: middle;
}

.userResultsOptions{
    font-size: 1.2vw;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.35);

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}
.userOption{
    cursor: pointer;
    padding: 1.2vh 1.2vw;
    margin: 0vh 0.5vw;
    border-style: solid;
    border-width: 0px;
    border-radius: 20px;
    border-color: rgb(240, 243, 248);
}
.userOption:hover{
    background-color: rgba(240, 243, 248, 0.055);
}
.userOption.selected{
    border-width: 1px;
}
.userResults{
    margin-top: 3vh;
    padding: 0vh 1vw;
}
.resultsIcon{
    font-size: 2.2vw;
    position: relative;
    bottom: 0.25vh;
}

/* SMALL SCREENS */
@media (max-width: 575px) {
    .userHeader{
        text-align: center;
        font-size: 4vw;
    }
    .userTitleIcon{
        font-size: 5vw;
    }
    .logout{
        font-size: 3vw;
    }
    .userResultsOptions{
        font-size: 4vw;
    }
    .resultsIcon{
        font-size: 5vw;
    }
}


/* SMALL SCREENS */
@media (max-width: 575px) {
    .userTitle{
        padding-left: 4vw !important;
        text-align: start;
    }
    .userResultsOptions{
        padding-left: 4vw !important;
        font-size: 3vw;
        justify-content: start !important;
        margin: 1vh 0vw;
    }
    .userOption{
        padding: 0.2vh 2vw;
    }
}